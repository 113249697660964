import React, { useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { TimeSeconds } from 'utils/helpers'
import moment from 'moment'
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit'
import { PROGRAM_IDS } from '../../../constants'

const PREFIX = 'PatientTable'

const classes = {
	root: `${PREFIX}-root`,
	container: `${PREFIX}-container`,
	rows: `${PREFIX}-rows`,
	visuallyHidden: `${PREFIX}-visuallyHidden`,
	tableHeader: `${PREFIX}-tableHeader`,
	compactSearch: `${PREFIX}-compactSearch`,
	appBar: `${PREFIX}-appBar`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {
		width: '100%',
		overflowX: 'auto',
		padding: theme.spacing(4),
	},
	[`& .${classes.container}`]: {
		overflowX: 'auto',
		maxHeight: `calc(100vh - 320px)`,
		minHeight: 400,
	},
	[`& .${classes.rows}`]: {
		flexDirection: 'column',
	},
	[`& .${classes.visuallyHidden}`]: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	[`& .${classes.tableHeader}`]: {
		borderBottom: '0px',
	},
	[`& .${classes.appBar}`]: {
		marginBottom: '12px',
	},
}))

const columns = [
	{
		id: 'name',
		label: 'Subject',
		format: (name, email) => {
			return (
				<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem' }}>
					<span style={{ fontWeight: 800 }}>{name || 'Unknown'}</span>
					{email && <span style={{ fontSize: '.8rem', color: '#999' }}>{email}</span>}
				</span>
			)
		},
		sortVal: (patient) => patient?.name?.toLowerCase() || '',
	},
	{
		id: 'created_at',
		label: 'Patient Since',
		format: (value) => {
			return moment(value).format('YYYY-MM-DD')
		},
		sortVal: (patient) => {
			return patient?.created_at ? new Date(patient?.created_at) : null
		},
	},
	{
		id: 'last_login',
		label: 'Activity',
		format: (value) => {
			const now = new Date().getTime()
			const time = new Date(value).getTime()
			return TimeSeconds((now - time) / 1000, 1)
		},
		sortVal: (patient) => {
			return patient?.last_login ? new Date(patient?.last_login) : null
		},
	},
	{
		id: 'chat',
		label: 'Chat',
		format: (value) => value,
	},
	{
		id: 'monitor',
		label: 'Monitor',
		format: (value) => value,
	},
]

function EnhancedTableHead(props) {
	const { order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.sortVal ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={order}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						) : null}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	[`&.${classes.root}`]: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))

export default function PatientTable({
	patient: patients,
	setCurrentPatient,
	fetchPatientPicture,
	onSetCurrentPatient,
	onChatPatient,
}) {
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('name')
	const [page, setPage] = React.useState(0)
	const [sortedFilteredPatients, setSortedFilteredPatients] = React.useState(patients)
	const [searchVal, setSearchVal] = React.useState('')
	const [rowsPerPage, setRowsPerPage] = React.useState(50)

	const context = useSendbirdStateContext()
	const sdkInstance = sendBirdSelectors.getSdk(context)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const sortTable = useCallback((patientsArr, order, orderBy) => {
		if (order && orderBy) {
			return patientsArr.sort((a, b) => {
				const column = columns.find(({ id }) => id === orderBy)

				const valA = column.sortVal(a)
				const valB = column.sortVal(b)

				if (order === 'desc') {
					return valA < valB ? 1 : -1
				} else {
					return valA < valB ? -1 : 1
				}
			})
		}

		return patientsArr
	})

	const filterTable = useCallback((patientsArr, query) => {
		const lowerQuery = query.toLowerCase()

		return query
			? patientsArr.filter((patient) => {
					const fieldsToCheck = [
						patient.name,
						patient.email,
						moment(patient.created_at).format('YYYY-MM-DD'),
						moment(patient.last_login).format('YYYY-MM-DD'),
					]

					return fieldsToCheck.some((field) => {
						return field?.toLowerCase()?.includes(lowerQuery)
					})
			  })
			: patientsArr || []
	})

	useEffect(() => {
		const filtered = filterTable(patients, searchVal)
		const sorted = sortTable(filtered, order, orderBy)
		setSortedFilteredPatients([...sorted])
	}, [order, orderBy, searchVal])

	return (
		<StyledPaper className={classes.root}>
			<AppBar className={classes.appBar} position="static" color="transparent" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<SearchIcon className={classes.block} color="inherit" />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								value={searchVal}
								onChange={(event) => {
									setSearchVal(event.target.value)
								}}
								placeholder="Search by name, email, or start date"
								InputProps={{
									disableunderline: 'true',
									className: classes.searchInput,
									inputProps: {
										className: classes.compactSearch,
									},
								}}
							/>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<TableContainer className={classes.container}>
				<Table size={'small'} stickyHeader aria-label="sticky table">
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={sortedFilteredPatients.length}
					/>
					<TableBody>
						{sortedFilteredPatients
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((patient, index) => {
								const intercomWorkspaceId =
									patient.program_id === PROGRAM_IDS.METAMUCIL
										? process.env.INTERCOM_APP_ID_METAMUCIL
										: process.env.INTERCOM_APP_ID_DIETA
								return (
									<StyledTableRow role="checkbox" tabIndex={-1} key={`${patient.id}-${index}`}>
										{columns.map((column) => {
											const value = patient[column.id]

											if (column.id === 'monitor') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																variant="contained"
																color="primary"
																onClick={() => {
																	setCurrentPatient(patient)
																	onSetCurrentPatient()
																}}
															>
																Monitor
															</Button>
														</Grid>
													</TableCell>
												)
											}
											if (column.id === 'chat') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																variant="contained"
																color="primary"
																onClick={() => {
																	//onChatPatient(patient, sdkInstance)
																	const ChatWindow = window.open(
																		`https://app.intercom.com/apps/${intercomWorkspaceId}/users/show?user_id=${patient.key}`,
																		'',
																		'menubar=1,resizable=1,width=1250,height=850,top=0,left=1000'
																	)
																}}
															>
																Chat
															</Button>
														</Grid>
													</TableCell>
												)
											}
											return (
												<TableCell key={column.id} align={column.align}>
													<Grid container direction="row" alignItems="center">
														{column.id === 'name' ? (
															<>
																<Avatar
																	src={patient.picture}
																	style={{
																		marginRight: 20,
																	}}
																	imgProps={{
																		onError: (e) => {
																			// fetchPatientPicture(patient)
																		},
																	}}
																/>
																{column.format(
																	patient.name,
																	patient.email || patient.key
																)}
															</>
														) : (
															<>{column?.format(value) || value}</>
														)}
													</Grid>
												</TableCell>
											)
										})}
									</StyledTableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="div"
				count={sortedFilteredPatients.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</StyledPaper>
	)
}
