import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import PatientTable from './PatientTable'
import Content from '@components/Content'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import { cacheDuration } from '../../../environment'

const PREFIX = 'index'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},
}))

const PatientContent = ({
	sub,
	location,
	auth,
	patient,
	getPatients,
	setCurrentPatient,
	fetchPatientPicture,
	getPatientData,
	onChatPatient,
}) => {
	useEffect(() => {
		if (patient.lastFetched + cacheDuration <= new Date().getTime() && !patient.loading.read) {
			getPatients()
		}
	}, [])

	useEffect(() => {
		if (sub) {
			const fetchPatientDetails = async () => {
				const roles = auth.app_metadata?.roles
				if (roles.includes('Admin')) {
					try {
						await setCurrentPatient({ key: sub })
						await getPatientData({ key: sub })
						navigate(paths.diary)
					} catch (err) {}
				}
			}
			fetchPatientDetails()
		}
	}, [sub])

	const activePatients = patient.data.filter((patient) => {
		return !patient.deleted_at
	})

	return (
		<Root className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{activePatients?.length ? (
						<PatientTable
							patient={activePatients}
							setCurrentPatient={setCurrentPatient}
							fetchPatientPicture={fetchPatientPicture}
							onSetCurrentPatient={() => {
								navigate(paths.diary)
							}}
							onChatPatient={(patient, SB) => {
								onChatPatient(patient, SB)
								navigate(paths.chat)
							}}
						/>
					) : (
						<Content
							title={'No patients associated!'}
							subtitle={'Have your patents connect with you via the Dieta Mobile app'}
						/>
					)}
				</Grid>
			</Grid>
		</Root>
	)
}

PatientContent.propTypes = {
	sub: PropTypes.string,
	location: PropTypes.object,
	auth: PropTypes.shape({}),
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
		lastFetched: PropTypes.number,
	}),
	getPatients: PropTypes.func.isRequired,
	setCurrentPatient: PropTypes.func.isRequired,
	getPatientData: PropTypes.func.isRequired,
	fetchPatientPicture: PropTypes.func.isRequired,
	onChatPatient: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patient }) => {
	return {
		auth,
		patient,
	}
}

export default connect(mapStateToProps, actions)(PatientContent)
